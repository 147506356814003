import React, {KeyboardEvent, SyntheticEvent, useRef, useState} from 'react';
import {removeItemInArrayString} from '../includes/utils';

import './EditableTextInput.scss';

type EditableTextInputProps = { value?: string, placeholder?: string, onPressEnter?: Function, onFocus?: Function };

function EditableTextInput({value = '', placeholder = '', onPressEnter, onFocus}: EditableTextInputProps) {

    const [classes, setClasses] = useState<string[]>(value?.length === 0 ? ['empty'] : []);
    const containerElRef = useRef(null);
    const inputElRef = useRef(null);

    const onFocusEditable = () => {
        setClasses(prevState => {
            if (prevState.indexOf("focused") === -1) {
                return [...prevState, 'focused'];
            }

            return prevState;
        });

        if (onFocus) {
            onFocus();
        }

    }

    const onBlur = () => {
        setClasses(prevState => {
            let newState = [...prevState];
            newState = removeItemInArrayString(newState, 'focused');
            return [...newState];
        });
    }


    const onEditing = (e: SyntheticEvent) => {

        if (!e.currentTarget.textContent || e.currentTarget.textContent.trim().length === 0) {
            setClasses(prevState => {

                return [...prevState, 'empty'];
            });
        } else {
            setClasses(prevState => {
                let newState = [...prevState];
                newState = removeItemInArrayString(newState, 'empty');
                return [...newState];
            });
        }
    }

    const handleKeyPress = (e: KeyboardEvent) => {

        if (e.key === 'Enter' && !e.shiftKey) {
            if (onPressEnter) {
                onPressEnter();
            }

            if (containerElRef.current) {
                // @ts-ignore
                inputElRef.current.blur();
            }
        }

    }

    const focusContainer = () => {
        // @ts-ignore
        inputElRef.current.focus();
    }

    return (
        <div className={"editable-text-input-container " + classes.join(' ')}
             ref={containerElRef}
             onClick={focusContainer}>
            <span className="placeholder">{placeholder}</span>
            <div className="editable-text-input"
                 contentEditable="true"
                 suppressContentEditableWarning={true}
                 onFocus={onFocusEditable}
                 onBlur={onBlur}
                 onKeyDown={handleKeyPress}
                 onInput={onEditing}
                 ref={inputElRef}>{value}</div>
        </div>
    );
}

export default EditableTextInput;
