import React from 'react';
import EditableTextInput from './EditableTextInput';

import './QuestionContainer.scss';

type QuestionContainerProps = { questionNumber: number };

function QuestionContainer({questionNumber}: QuestionContainerProps) {

    const onPressEnterHandler = () => {
        console.log('Pressed enter');
    }

    return (
        <div className="question-container">
            <div className="question-number"><span>D{questionNumber}</span></div>
            <div className="question-input">
                <EditableTextInput
                    placeholder="Inserisci il testo della domanda"
                    onPressEnter={onPressEnterHandler}/>
            </div>
        </div>
    );
}

export default QuestionContainer;
