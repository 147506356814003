import React, {useState} from 'react';
import './App.scss';
import EditQuestion from './Editor/EditQuestion';
import AddQuestion from './Editor/AddQuestion';
import {generateUUID} from './includes/utils';

function App() {

    const [questions, setQuestions] = useState([{id: generateUUID()}]);

    const addNewQuestionHandler = () => {
        setQuestions(prevState => {
            return [...prevState,
                {
                    id: generateUUID(),
                },
            ];
        })

    };

    const renderQuestions = () => {
        let components: any[] = [];
        questions.forEach((question: any, position) => {
            components.push(<EditQuestion key={question.id}
                                          position={position + 1}/>)
        });
        return components;
    }

    return (
        <div className="App">
            {renderQuestions()}
            <AddQuestion addNewQuestionEvent={addNewQuestionHandler}/>
        </div>
    );
}

export default App;
