import React, {useState} from 'react';

import './AnswersContainer.scss'
import AnswerInput from './AnswerInput';
import {generateUUID} from '../includes/utils';

type Answer = { id: string, text: string };

export default function AnswersContainer() {

    const [answers, setAnswers] = useState<Answer[]>([
        {id: generateUUID(), text: ''},
        {id: generateUUID(), text: ''},
        {id: generateUUID(), text: ''},
    ]);

    const removeAnswerHandler = (itemId: string) => {

        const answersFiltered = answers.filter((answer) => {
            return answer.id !== itemId
        });
        setAnswers(answersFiltered);
    }

    const addAnswerHandler = (itemId: string) => {
        const newAnswer = {
            id: generateUUID(),
            text: '',
        };

        const itemPosition = findAnswerPositionById(answers, itemId);

        let answersCopy = [...answers];
        answersCopy.splice(itemPosition + 1, 0, newAnswer);
        setAnswers(answersCopy);
    }

    const onFocusHandler = (itemId: string) => {
        const itemPosition = findAnswerPositionById(answers, itemId);
        if (itemPosition === answers.length - 1) {
            addAnswerHandler(itemId);
        }
    }

    const generateEditableInput = () => {
        let components: any[] = [];

        const canRemove = answers.length > 1;
        answers.forEach((answer) => {
            components.push(<AnswerInput placeholder="Inserisci un'opzione di risposta"
                                         answerId={answer.id}
                                         addAnswer={addAnswerHandler}
                                         removeAnswer={removeAnswerHandler}
                                         text={answer.text}
                                         canRemove={canRemove}
                                         onFocus={onFocusHandler}
                                         key={answer.id}/>);
        });

        return components;
    }

    return (
        <div className="answers-container">
            {generateEditableInput()}
        </div>
    );
}

function findAnswerPositionById(answers: Answer[], answerId: string) {
    return answers.findIndex((answer) => {
        return answer.id === answerId;
    });
}
