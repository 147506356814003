import React from 'react';

import './AddQuestion.scss';

type AddQuestionProps = { addNewQuestionEvent: Function }

function AddQuestion({addNewQuestionEvent}: AddQuestionProps) {

    return (
        <div className="add-question-container">
            <div className="button" onClick={() => addNewQuestionEvent()}>
                <span className="las la-plus-circle la-2x"/>
                <span className="bold">NUOVA DOMANDA</span>
            </div>

        </div>
    );
}

export default AddQuestion;
