import React, {MouseEventHandler} from 'react';
import EditableTextInput from './EditableTextInput';

import './AnswerInput.scss';

type AnswerInputProps = { answerId: string, text: string, placeholder?: string, canRemove?: boolean, addAnswer: Function, removeAnswer: Function, onFocus?: Function };

function AnswerInput({answerId, text, placeholder = '', canRemove = true, addAnswer, removeAnswer, onFocus}: AnswerInputProps) {

    const addAnswerHandler: MouseEventHandler = (event) => {
        addAnswer(answerId);
    }

    const removeAnswerHandler: MouseEventHandler = (event) => {
        removeAnswer(answerId);
    }

    const onFocusHandler = () => {
        onFocus && onFocus(answerId);
    }

    return (
        <div className="answer-input">
            <div className="dragger">
                {/*<i className="las la-bars la-2x pointer"/>*/}
            </div>
            <EditableTextInput placeholder={placeholder}
                               value={text}
                               onFocus={onFocusHandler}/>
            <div className="actions">
                {canRemove && <i className="las la-minus-circle la-2x pointer" onClick={removeAnswerHandler}/>}
                <i className="las la-plus-circle la-2x pointer" onClick={addAnswerHandler}/>
            </div>
        </div>
    );

}

export default AnswerInput;
