import React from 'react';
import QuestionContainer from './QuestionContainer';
import AnswersContainer from './AnswersContainer';

import './EditQuestion.scss';

function EditQuestion({position}: any) {
    return (
        <div className="edit-question">
            <QuestionContainer questionNumber={position}/>
            <span className="divider"/>
            <AnswersContainer/>
        </div>
    );
}

export default EditQuestion;
